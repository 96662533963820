import { createSlice } from '@reduxjs/toolkit';
import { IStatus } from '../../../@types/status';
import { getAnalytics, getAnalyticsBalanced, getBalancePerMonth, getBalancePerMonthPerOffice, getCashDeskAnalytics, getCodesAnalytics, getExpensesAnalytics, getKpiStatResponse, getKpiStatResponseByRange, getNbStatClientResponsesByOffice, getNbStatClientResponsesByStatClientByOffice, getNbStatsClientResponseByMonth, } from './actions';
import { initialState } from './initialState';
var slice = createSlice({
    name: 'analytics',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder
            .addCase(getAnalytics.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getAnalytics.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.analytics = action.payload;
        })
            .addCase(getAnalytics.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        builder
            .addCase(getNbStatClientResponsesByOffice.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getNbStatClientResponsesByOffice.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.nbStatClientResponsesByOffice = action.payload;
        })
            .addCase(getNbStatClientResponsesByOffice.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(getAnalyticsBalanced.pending, function (state) {
            state.statusBalance = IStatus.LOADING;
        })
            .addCase(getAnalyticsBalanced.fulfilled, function (state, action) {
            state.statusBalance = IStatus.SUCCEEDED;
            state.balance = action.payload;
        })
            .addCase(getAnalyticsBalanced.rejected, function (state) {
            state.statusBalance = IStatus.FAILED;
        })
            .addCase(getBalancePerMonth.pending, function (state) {
            state.statusBalancePerMonth = IStatus.LOADING;
        })
            .addCase(getBalancePerMonth.fulfilled, function (state, action) {
            state.statusBalancePerMonth = IStatus.SUCCEEDED;
            state.balancePerMonth = action.payload;
        })
            .addCase(getBalancePerMonth.rejected, function (state) {
            state.statusBalancePerMonth = IStatus.FAILED;
        })
            .addCase(getBalancePerMonthPerOffice.pending, function (state) {
            state.statusBalancePerMonth = IStatus.LOADING;
        })
            .addCase(getBalancePerMonthPerOffice.fulfilled, function (state, action) {
            state.statusBalancePerMonth = IStatus.SUCCEEDED;
            state.balancePerMonth = action.payload;
        })
            .addCase(getBalancePerMonthPerOffice.rejected, function (state) {
            state.statusBalancePerMonth = IStatus.FAILED;
        })
            .addCase(getNbStatClientResponsesByStatClientByOffice.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getNbStatClientResponsesByStatClientByOffice.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.statsClientResponse = action.payload.nbStatClientResponsesByStatClientByOffice;
        })
            .addCase(getNbStatClientResponsesByStatClientByOffice.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(getNbStatsClientResponseByMonth.pending, function (state) {
            state.statusStatsClientResponseByMonth = IStatus.LOADING;
        })
            .addCase(getNbStatsClientResponseByMonth.fulfilled, function (state, action) {
            state.statusStatsClientResponseByMonth = IStatus.SUCCEEDED;
            state.statsClientResponseByMonth = action.payload;
        })
            .addCase(getNbStatsClientResponseByMonth.rejected, function (state) {
            state.statusStatsClientResponseByMonth = IStatus.FAILED;
        })
            .addCase(getKpiStatResponse.pending, function (state) {
            state.statusKpi = IStatus.LOADING;
        })
            .addCase(getKpiStatResponse.fulfilled, function (state, action) {
            state.statusKpi = IStatus.SUCCEEDED;
            state.kpiResponse = action.payload;
        })
            .addCase(getKpiStatResponse.rejected, function (state) {
            state.statusKpi = IStatus.FAILED;
        })
            .addCase(getKpiStatResponseByRange.pending, function (state) {
            state.statusKpiStatRange = IStatus.LOADING;
        })
            .addCase(getKpiStatResponseByRange.fulfilled, function (state, action) {
            state.statusKpiStatRange = IStatus.SUCCEEDED;
            state.kpiResponseStatRange = action.payload;
        })
            .addCase(getKpiStatResponseByRange.rejected, function (state) {
            state.statusKpiStatRange = IStatus.FAILED;
        })
            // expenses
            .addCase(getCodesAnalytics.pending, function (state) {
            state.statusCodesAnalytics = IStatus.LOADING;
        })
            .addCase(getCodesAnalytics.fulfilled, function (state, action) {
            state.statusCodesAnalytics = IStatus.SUCCEEDED;
            state.codesAnalytics = action.payload;
        })
            .addCase(getCodesAnalytics.rejected, function (state) {
            state.statusCodesAnalytics = IStatus.FAILED;
        })
            // expenses
            .addCase(getExpensesAnalytics.pending, function (state) {
            state.statusExpensesAnalytics = IStatus.LOADING;
        })
            .addCase(getExpensesAnalytics.fulfilled, function (state, action) {
            state.statusExpensesAnalytics = IStatus.SUCCEEDED;
            state.expensesAnalytics = action.payload;
        })
            .addCase(getExpensesAnalytics.rejected, function (state) {
            state.statusExpensesAnalytics = IStatus.FAILED;
        })
            // cash desks
            .addCase(getCashDeskAnalytics.pending, function (state) {
            state.statusCashDesksAnalytics = IStatus.LOADING;
        })
            .addCase(getCashDeskAnalytics.fulfilled, function (state, action) {
            state.statusCashDesksAnalytics = IStatus.SUCCEEDED;
            state.cashDesksAnalytics = action.payload;
        })
            .addCase(getCashDeskAnalytics.rejected, function (state) {
            state.statusCashDesksAnalytics = IStatus.FAILED;
        });
    },
});
export default slice.reducer;
